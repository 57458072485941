var $ = require("jquery");

$(document).ready(function () {

    var $mobilePlayButton = $("header .mobile .play-button");
    var $desktopPlayButton = $("header .desktop .play-button");

    var $mobileVideo = $("header .mobile .header-video");
    var $desktopVideo = $("header .desktop .header-video");

    $mobilePlayButton.click(function () {
        $mobileVideo.get(0).play();
        $mobilePlayButton.remove();
        $mobileVideo.attr('controls', true);
    });

    $desktopPlayButton.click(function () {
        $desktopVideo.get(0).play();
        $desktopPlayButton.remove();
        $desktopVideo.attr('controls', true);
    });


});