var $ = require("jquery");
import 'simple-load-more';

// !function(c){c.fn.simpleLoadMore=function(l){var d=c.extend({item:"",count:5,itemsToLoad:5,cssClass:"load-more",showCounter:!1,counterText:"Showing {showing} out of {total}",btnHTML:"",btnText:"View More",btnWrapper:"",btnWrapperClass:"",easing:"fade",easingDuration:400},l);c(this).each(function(t,n){var e=d.item,a=d.count,s=(d.itemsToLoad,d.cssClass),o=d.showCounter,r=d.counterText;btnHTML=d.btnHTML,btnText=d.btnText,btnWrapper=d.btnWrapper,btnWrapperClass=d.btnWrapperClass,btnWrapper||!1===btnWrapper||(btnWrapper='<div class="'+s+"__btn-wrap"+(btnWrapperClass?" "+btnWrapperClass:"")+'"></div>');var i=c(this),p=i.find(e),r=c('<p class="'+s+'__counter">'+r+"</p>");o&&i.append(r),btnHTML=btnHTML||'<a href="#" class="'+s+'__btn">'+btnText+"</a>",o=c(btnHTML),l.itemsToLoad&&!isNaN(l.itemsToLoad)||(d.itemsToLoad=d.count),i.addClass(s),p.addClass(s+"__item"),!i.find("."+s+"__btn").length&&p.length>d.count&&i.append(o),o.add(r).html(function(t,n){return n.replace("{showing}",'<span class="'+s+"__count "+s+'__count--showing">'+a+"</span>").replace("{total}",'<span class="'+s+"__count "+s+'__count--total">'+p.length+"</span>")});r=i.find("."+s+"__btn");r.length||(r=o),p.length>d.count&&p.slice(d.count).hide(),r.wrapAll(btnWrapper),r.on("click",function(t){t.preventDefault();var n=c(this),e=p.filter(":hidden"),t=e;0<(t=-1!==d.itemsToLoad&&0<d.itemsToLoad?e.slice(0,d.itemsToLoad):t).length&&("fade"===d.easing?t.fadeIn(d.easingDuration):t.slideDown(d.easingDuration)),i.find("."+s+"__count--showing").text(p.filter(":visible").length),(e.length<=d.itemsToLoad||-1===d.itemsToLoad)&&(n.parent("."+s+"__btn-wrap")?n.parent():n).remove()})})}}(jQuery);

$(document).ready(function () {
    if (window.innerWidth < 768) {
        $('#card-group').simpleLoadMore({
            item: '.col-md-6.col-lg-4.py-md-3',
            count: 3,
        });
    }
});