var $ = require("jquery");
import Collapse from "bootstrap/js/dist/collapse";

$(document).ready(function () {

    if (window.innerWidth < 1200) {

        var height = $(".navbar-collapse").css("height");
        const navLinks = document.querySelectorAll('.nav-item')
        const menuToggle = document.getElementById('navbarSupportedContent')
        const bsCollapse = new Collapse(menuToggle, {toggle: false})

        navLinks.forEach((l) => {
            l.addEventListener('click', () => {
                bsCollapse.toggle()
            })
        })
    }

    if (window.innerWidth < 1200) {
        $("main section").css("scroll-margin-top", (parseInt(height, 10) + 160) + "px");
    }

});