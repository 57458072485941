// Load Bootstrap JS
import Scrollspy from "bootstrap/js/src/scrollspy";
var jQuery = require("jquery");

// Load Styles
import '../scss/main.scss';

// App code
// console.log(`Hello ${process.env.HELLO}`);

import './video';
import './nav';
import './book-now';
import './examples';
import './swiper'
// import './test'
import './book'
// import './mixitup'
// import './pagination'
import './jPages'
import './loadMore'