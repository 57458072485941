import Swiper, {Pagination, Autoplay, Navigation} from 'swiper';

Swiper.use([Pagination, Autoplay, Navigation]);
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

const swiperSponsors = new Swiper('#sponsors .swiper', {
    loop: true,
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
    breakpoints: {
        568: {
            slidesPerView: 2
        },
        992: {
            slidesPerView: 3,
        },
        1400: {
            slidesPerView: 4,
        }
    }
});

const swiperNews = new Swiper('#news .swiper', {
    loop: true,
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
    spaceBetween: 100,
    breakpoints: {
        568: {
            slidesPerView: 2,
            spaceBetween: 48
        },
        992: {
            slidesPerView: 3,
            spaceBetween: 48
        },
        1400: {
            slidesPerView: 3,
            spaceBetween: 48
        }
    }
});

const swiperPresse = new Swiper('#press .swiper', {
    loop: true,
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
    spaceBetween: 100,
    breakpoints: {
        768: {
            slidesPerView: 2,
            spaceBetween: 48
        },
        992: {
            slidesPerView: 3,
            spaceBetween: 48
        },
        1400: {
            slidesPerView: 4,
            spaceBetween: 48
        }
    }
});