var $ = require("jquery");

$(document).ready(function () {

    if (document.getElementById("book-now")) {
        var $bookNow = $("#book-now");

        var $showButton = $("#sticker .show");
        var $closeButton = $("#book-now .close");

        var $posStickerBookNow = $("#sticker .book-now").position();
        var $posSticker = $("#sticker").position();
        // $bookNow.css("top", "calc(" + parseInt($posStickerBookNow.top, 10) + "px + " + parseInt($posSticker.top, 10) + "px + 1rem)");
        $bookNow.css("top", "calc(" + parseInt($posStickerBookNow.top, 10) + "px + " + parseInt($posSticker.top, 10) + "px + 1rem)");

        $showButton.click(function () {
            $showButton.css("opacity", 0);
            $bookNow.css("display", "block");
            $bookNow.css("opacity", 1);
            setTimeout(function () {
                $showButton.css("display", "none");
            }, 300);
        });

        $closeButton.click(function () {
            $showButton.css("opacity", 1);
            $bookNow.css("opacity", 0);
            $showButton.css("display", "block");
            setTimeout(function () {
                $bookNow.css("display", "none");
            }, 300);
        });

    }
});