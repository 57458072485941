var $ = require("jquery");

$(document).ready(function () {

    if (true) {
        setTimeout(function () {
            var examples = document.getElementById('examples');


            if (examples) {

                var height = examples.clientHeight;
                var bg1height = $("#examples .bg1").css("height");
                var bg3 = $("#examples .bg3");

                function sizeWindow() {
                    height = document.getElementById('examples').clientHeight;
                    bg1height = $("#examples .bg1").css("height");
                    bg3 = $("#examples .bg3");
                    let bg2height;
                    let bg2;
                    if (window.innerWidth < 768) {
                        bg3.css("top", parseInt(height, 10) - parseInt(bg1height, 10) - 580 + "px");
                    } else {
                        bg2 = $("#examples .bg2");
                        bg2.css("height", (height / 2) - parseInt(bg1height, 10) + "px");
                        bg2height = $("#examples .bg2").css("height");
                        bg3.css("height", (height / 2));
                        bg3.css("top", parseInt(bg2height, 10) - 90 + "px");
                    }
                }

                $(window).resize(function () {
                    sizeWindow();
                });

                sizeWindow();
            }
        }, 1000);
    }
});