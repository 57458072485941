var $ = require("jquery");

$(document).ready(function () {

    var $bginfo = $("#book .mix-blend-mode");

    if (window.innerWidth < 1200) {

        var $height = $("#book picture").css("height");

        $bginfo.css("padding-top", $height);
    } else {
        $bginfo.css("padding-top", '16rem');
    }
});