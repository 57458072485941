var $ = require("jquery");
import './jPagesSrc'

$(document).ready(function () {
    $(function () {

        // $("div.holder").jPages({
        //     containerID: "itemContainer",
        //     perPage: 6,
        //     previous: '',
        //     next: '',
        // });

        if (window.innerWidth > 768) {

            $("div.pagination").jPages({
                containerID: "card-group",
                perPage: 6,
                previous: 'Zurück',
                next: 'Weiter',
            });
        }
    });
});